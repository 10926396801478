import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import { Container, Spinner, Form } from 'react-bootstrap';
import SETTINGS from '../SETTINGS';

function AffiliateGraph() {
  const [loading, setLoading] = useState(true);
  const [affiliateData, setAffiliateData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [viewMode, setViewMode] = useState('tree'); // 'tree' or 'force'
  const [rootNode, setRootNode] = useState(null);
  
  useEffect(() => {
    // Get data from SETTINGS
    if (SETTINGS && SETTINGS.AFFILIATE_DATA) {
      processData(SETTINGS.AFFILIATE_DATA);
    }
  }, []);
  
  const processData = (rawData) => {
    setLoading(true);
    
    const nodes = new Map();
    const links = [];
    
    // Create a node for each unique ID
    rawData.affiliateStructure.forEach(relation => {
      if (!nodes.has(relation.inviter)) {
        nodes.set(relation.inviter, {
          id: relation.inviter,
          group: 1,
          children: []
        });
      }
      
      if (!nodes.has(relation.invitee)) {
        nodes.set(relation.invitee, {
          id: relation.invitee,
          group: 2,
          children: []
        });
      }
      
      // Add invitee as child of inviter
      const inviter = nodes.get(relation.inviter);
      inviter.children.push(relation.invitee);
      
      // Create link
      links.push({
        source: relation.inviter,
        target: relation.invitee,
        value: 1
      });
    });
    
    // Convert Map to array
    const nodesArray = Array.from(nodes.values());
    
    // Find root nodes (nodes that are not invitees)
    const invitees = new Set(links.map(link => link.target));
    const rootNodes = nodesArray.filter(node => !invitees.has(node.id));
    
    // Set the first root node as default
    if (rootNodes.length > 0 && !rootNode) {
      setRootNode(rootNodes[0].id);
    }
    
    const graphData = {
      nodes: nodesArray,
      links: links,
      rootNodes: rootNodes
    };
    
    setAffiliateData(graphData);
    setFilteredData(graphData);
    setLoading(false);
  };
  
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    
    if (!query.trim()) {
      setFilteredData(affiliateData);
      return;
    }
    
    // Filter nodes based on ID
    const filteredNodes = affiliateData.nodes.filter(node => 
      node.id.includes(query)
    );
    
    // Get IDs of filtered nodes
    const filteredIds = new Set(filteredNodes.map(node => node.id));
    
    // Filter links that connect filtered nodes
    const filteredLinks = affiliateData.links.filter(link => 
      filteredIds.has(link.source) && filteredIds.has(link.target)
    );
    
    setFilteredData({
      ...affiliateData,
      nodes: filteredNodes,
      links: filteredLinks
    });
  };
  
  const handleRootChange = (e) => {
    setRootNode(e.target.value);
  };
  
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };
  
  useEffect(() => {
    if (!filteredData) return;
    
    if (viewMode === 'tree') {
      renderTreeGraph();
    } else {
      renderForceGraph();
    }
  }, [filteredData, selectedNode, viewMode, rootNode]);
  
  // Create hierarchical tree data structure
  const createHierarchy = (rootId) => {
    if (!filteredData || !rootId) return null;
    
    const buildTree = (nodeId, visited = new Set()) => {
      if (visited.has(nodeId)) return null; // Prevent circular references
      visited.add(nodeId);
      
      const node = filteredData.nodes.find(n => n.id === nodeId);
      if (!node) return null;
      
      const children = filteredData.links
        .filter(link => link.source === nodeId || (link.source.id && link.source.id === nodeId))
        .map(link => buildTree(link.target.id || link.target, new Set(visited)))
        .filter(Boolean);
      
      return {
        name: node.id,
        children: children.length > 0 ? children : null,
        data: node
      };
    };
    
    return buildTree(rootId);
  };
  
  const renderTreeGraph = () => {
    // Clear previous SVG
    d3.select("#affiliate-graph-container").selectAll("*").remove();
    
    if (!rootNode) return;
    
    const width = 1000;
    const height = 800;
    const hierarchy = createHierarchy(rootNode);
    
    if (!hierarchy) {
      d3.select("#affiliate-graph-container")
        .append("text")
        .attr("x", width / 2)
        .attr("y", height / 2)
        .attr("text-anchor", "middle")
        .text("No data for selected root node");
      return;
    }
    
    const svg = d3.select("#affiliate-graph-container")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);
    
    const g = svg.append("g");
    
    // Create tree layout
    const treeLayout = d3.tree()
      .size([width - 200, height - 100])
      .nodeSize([15, 120]); // Adjust node spacing
    
    const root = d3.hierarchy(hierarchy);
    treeLayout(root);
    
    // Add links
    const link = g.selectAll(".link")
      .data(root.links())
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", d => {
        return `M${d.source.x},${d.source.y}
                C${d.source.x},${(d.source.y + d.target.y) / 2}
                 ${d.target.x},${(d.source.y + d.target.y) / 2}
                 ${d.target.x},${d.target.y}`;
      })
      .attr("fill", "none")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .attr("stroke-width", 1.5);
    
    // Add nodes
    const node = g.selectAll(".node")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", d => `translate(${d.x},${d.y})`)
      .on("click", (event, d) => {
        setSelectedNode(d.data.name);
      });
    
    // Add node circles
    node.append("circle")
      .attr("r", d => {
        const nodeData = filteredData.nodes.find(n => n.id === d.data.name);
        const childCount = nodeData?.children ? nodeData.children.length : 0;
        return 5 + (childCount * 0.5);
      })
      .attr("fill", d => {
        if (selectedNode && selectedNode === d.data.name) {
          return "#ff3939"; // Highlight selected node
        }
        
        const nodeData = filteredData.nodes.find(n => n.id === d.data.name);
        const childCount = nodeData?.children ? nodeData.children.length : 0;
        
        if (childCount > 10) return "#3949ff"; // Top inviters
        if (childCount > 5) return "#39a0ff"; // Medium inviters
        if (childCount > 0) return "#39d5ff"; // Small inviters
        return "#c9c9c9"; // No invitees
      })
      .attr("stroke", "#fff")
      .attr("stroke-width", 1.5);
    
    // Add node labels
    node.append("text")
      .attr("dy", -10)
      .attr("x", 0)
      .attr("text-anchor", "middle")
      .text(d => d.data.name)
      .style("font-size", "10px")
      .style("fill", "#333");
    
    // Add hover tooltips
    node.append("title")
      .text(d => {
        const nodeData = filteredData.nodes.find(n => n.id === d.data.name);
        const childCount = nodeData?.children ? nodeData.children.length : 0;
        return `ID: ${d.data.name}\nInvitees: ${childCount}`;
      });
    
    // Add zoom functionality
    const zoom = d3.zoom()
      .scaleExtent([0.1, 3])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
      });
    
    svg.call(zoom);
    
    // Center the tree initially
    svg.call(zoom.transform, d3.zoomIdentity.translate(width / 2, 50));
  };
  
  const renderForceGraph = () => {
    // Clear previous SVG
    d3.select("#affiliate-graph-container").selectAll("*").remove();
    
    const width = 800;
    const height = 600;
    
    const svg = d3.select("#affiliate-graph-container")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);
    
    // Create a force simulation
    const simulation = d3.forceSimulation(filteredData.nodes)
      .force("link", d3.forceLink(filteredData.links).id(d => d.id).distance(100))
      .force("charge", d3.forceManyBody().strength(-300))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force("x", d3.forceX())
      .force("y", d3.forceY());
    
    // Add links
    const link = svg.append("g")
      .selectAll("line")
      .data(filteredData.links)
      .enter()
      .append("line")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .attr("stroke-width", d => Math.sqrt(d.value));
    
    // Add nodes
    const node = svg.append("g")
      .selectAll("circle")
      .data(filteredData.nodes)
      .enter()
      .append("circle")
      .attr("r", d => {
        // Size based on number of children (invitees)
        const childCount = d.children ? d.children.length : 0;
        return 5 + (childCount * 0.5);
      })
      .attr("fill", d => {
        // Color nodes differently based on role
        if (selectedNode && selectedNode === d.id) {
          return "#ff3939"; // Highlight selected node
        }
        
        const childCount = d.children ? d.children.length : 0;
        if (childCount > 10) return "#3949ff"; // Top inviters
        if (childCount > 5) return "#39a0ff"; // Medium inviters
        if (childCount > 0) return "#39d5ff"; // Small inviters
        return "#c9c9c9"; // No invitees
      })
      .call(drag(simulation))
      .on("click", (event, d) => {
        setSelectedNode(d.id);
      });
    
    // Add labels
    const label = svg.append("g")
      .selectAll("text")
      .data(filteredData.nodes)
      .enter()
      .append("text")
      .attr("dx", 12)
      .attr("dy", ".35em")
      .text(d => d.id)
      .style("font-size", "10px")
      .style("fill", "#333");
    
    // Add title for hover tooltip
    node.append("title")
      .text(d => {
        const childCount = d.children ? d.children.length : 0;
        return `ID: ${d.id}\nInvitees: ${childCount}`;
      });
    
    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);
      
      node
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);
      
      label
        .attr("x", d => d.x)
        .attr("y", d => d.y);
    });
    
    // Drag function
    function drag(simulation) {
      function dragstarted(event) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      }
      
      function dragged(event) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }
      
      function dragended(event) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      }
      
      return d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    }
    
    // Add zoom functionality
    svg.call(d3.zoom()
      .extent([[0, 0], [width, height]])
      .scaleExtent([0.1, 8])
      .on("zoom", (event) => {
        node.attr("transform", event.transform);
        link.attr("transform", event.transform);
        label.attr("transform", event.transform);
      })
    );
  };
  
  const getStatistics = () => {
    if (!affiliateData) return null;
    
    const totalNodes = affiliateData.nodes.length;
    const totalLinks = affiliateData.links.length;
    
    // Find top inviters
    const inviters = {};
    affiliateData.links.forEach(link => {
      if (!inviters[link.source.id ? link.source.id : link.source]) {
        inviters[link.source.id ? link.source.id : link.source] = 0;
      }
      inviters[link.source.id ? link.source.id : link.source]++;
    });
    
    const topInviters = Object.entries(inviters)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);
    
    return {
      totalNodes,
      totalLinks,
      topInviters
    };
  };
  
  const stats = getStatistics();
  
  return (
    <Container className="mt-4" style={{backgroundColor:"#FFFFFF"}}>
      <h2>Affiliate Structure Graph</h2>
      <hr />
      
      {loading ? (
        <div className="text-center py-5">
          <Spinner animation="border" role="status" />
          <div className="mt-2">Loading graph...</div>
        </div>
      ) : (
        <div>
          {/* View Mode Selection */}
          <div className="mb-3 d-flex">
            <div className="btn-group me-3">
              <button 
                className={`btn ${viewMode === 'tree' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleViewModeChange('tree')}
              >
                Tree View
              </button>
              <button 
                className={`btn ${viewMode === 'force' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleViewModeChange('force')}
              >
                Force Layout
              </button>
            </div>
            
            {/* Root Node Selection (only for tree view) */}
            {viewMode === 'tree' && affiliateData?.rootNodes && (
              <Form.Select 
                value={rootNode || ''}
                onChange={handleRootChange}
                className="w-auto"
              >
                <option value="">Select Root Node</option>
                {affiliateData.rootNodes.map(node => (
                  <option key={node.id} value={node.id}>
                    Node {node.id} ({node.children ? node.children.length : 0} direct invitees)
                  </option>
                ))}
              </Form.Select>
            )}
          </div>
          
          {/* Statistics */}
          {stats && (
            <div className="mb-3 p-3 bg-light rounded">
              <strong>Total Users:</strong> {stats.totalNodes}
              <br />
              <strong>Total Connections:</strong> {stats.totalLinks}
              <br />
              <strong>Top Inviters:</strong>
              <ul>
                {stats.topInviters.map(([id, count]) => (
                  <li key={id}>
                    ID: {id} - {count} invitees
                  </li>
                ))}
              </ul>
            </div>
          )}
          
          {/* Search Input */}
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Search by ID"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Form.Group>
          
          {/* Selected Node Info */}
          {selectedNode && (
            <div className="mb-3 p-3 bg-info bg-opacity-10 border border-info rounded">
              <h5>Selected: ID {selectedNode}</h5>
              {affiliateData?.nodes.find(n => n.id === selectedNode)?.children && (
                <div>
                  <strong>Invitees: </strong>
                  {affiliateData.nodes.find(n => n.id === selectedNode).children.length}
                </div>
              )}
            </div>
          )}
          
          {/* Graph Container */}
          <div 
            id="affiliate-graph-container" 
            className="border rounded p-2 mb-3" 
            style={{ minHeight: '700px', overflow: 'hidden' }}
          ></div>
          
          <div className="text-muted small mb-3">
            Note: Drag nodes to reposition. Scroll to zoom in/out.
          </div>
        </div>
      )}
    </Container>
  );
}

export default AffiliateGraph;