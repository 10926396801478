// src/App.js

import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Logo from './images/logo.png'; 

import ConnectButton from './components/ConnectButton';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './App.css'; // Import your custom CSS

import { Container, Nav, Navbar, NavDropdown, Row, Col } from 'react-bootstrap';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

import Dashboard from './components/Dashboard';
import Nodes from './components/Nodes';
import BuyToken from './components/BuyToken';
import BuyToken2 from './components/BuyToken2';
import DexScreener from './components/DexScreener';
import Staking from './components/Staking';
import StakingPromo from './components/StakingPromo';
import Admin from './components/Admin';
import AdminNew from './components/AdminNew';
import AdminStake from './components/AdminStake';
import AffiliateGraph from './components/AffiliateGraph';
import SETTINGS from './SETTINGS';
import Footer from './components/Footer';
import StakingPromoBanner from './components/StakingPromoBanner';




function App() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = () => {
    setExpanded(false);
  };


 

   return (
    <Router>

 <Navbar expand="lg" className="top-bar" expanded={expanded}>
        <Container>
          
          <Navbar.Brand as={Link} to="/" onClick={handleLinkClick}>
            <img src={Logo} style={{ width: "125px", marginTop:"6px" }} alt={"Logo"} />
          </Navbar.Brand>
          <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
            <ConnectButton />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : 'expanded')} />
          
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/" className="menuBtn" onClick={handleLinkClick}>Home</Nav.Link>
              <NavDropdown title="Buy" id="basic-nav-dropdown" className="">
              <NavDropdown.Item as={Link} to="/nodes" onClick={handleLinkClick}>
                Buy NODE
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/dex" onClick={handleLinkClick}>
                Buy on DEX
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/buy-token" onClick={handleLinkClick}>
                ${SETTINGS.tokenSymbol} Presale #1
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/buy-token2" onClick={handleLinkClick}>
                ${SETTINGS.tokenSymbol} Presale #2
              </NavDropdown.Item>
            </NavDropdown>
              <Nav.Link as={Link} to="/staking" className="menuBtn" onClick={handleLinkClick}>Stake</Nav.Link>
              {isConnected && address.toLowerCase() === SETTINGS.owner.toLowerCase() && (
          
                <NavDropdown title="Admin" id="basic-nav-dropdown" className="">
                <NavDropdown.Item as={Link} to="/adminnew" onClick={handleLinkClick}>
                  Nodes
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/adminstake" onClick={handleLinkClick}>
                  Stakes
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/affiliategraph" onClick={handleLinkClick}>
                  Graph
                </NavDropdown.Item>
              </NavDropdown>
              )}
              <Nav.Link as={Link} to="https://spacemonkeez.io/" className="menuBtn" onClick={handleLinkClick}>Website</Nav.Link>
            
              
              
              
           
            </Nav>
          </Navbar.Collapse>
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <ConnectButton />
          </div>
        </Container>
      </Navbar>
      <Container style={{  minHeight: '100vh', marginTop:"10px" }}>
      <StakingPromoBanner />
        <Routes>
        {isConnected && address.toLowerCase() === SETTINGS.owner.toLowerCase() && (
          <>
       
            <Route path="/adminnew" element={<AdminNew provider={walletProvider} account={address} />} />
            <Route path="/adminstake" element={<AdminStake provider={walletProvider} account={address} />} />
            <Route path="/affiliategraph" element={<AffiliateGraph provider={walletProvider} account={address} />} />
            </>
          )}
        <Route path="/staking" element={<Staking provider={walletProvider} account={address} />} />
        <Route path="/staking-promo" element={<StakingPromo provider={walletProvider} account={address} />} />
        <Route path="/buy-token" element={<BuyToken provider={walletProvider} account={address} />} />
        <Route path="/buy-token2" element={<BuyToken2 provider={walletProvider} account={address} />} />
        <Route path="/nodes" element={<Nodes provider={walletProvider} account={address} />} />
        <Route path="/dex" element={<DexScreener provider={walletProvider} account={address} />} />
          <Route path="/" element={<Dashboard provider={walletProvider} address={address} isConn={isConnected} />} />
        
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
