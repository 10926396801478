import React from "react";
import { useNavigate } from "react-router-dom";
import "animate.css";

const StakingPromoBanner = () => {
  const navigate = useNavigate();

  const openStakeToken = () => {
    navigate("/staking-promo");
  };

  return (
    <div className="container my-4">
      <div className="card pokemon-card mb-3 shadow">
        <div className="row g-0">
          {/* Left column with image */}
          <div className="col-md-3 animate__animated animate__fadeInLeft">
            <img 
              src="coins.webp" 
              className="img-fluid rounded-start" 
              alt="Staking Promotion" 
              style={{height:"250px"}}
            />
          </div>
          {/* Right column with text */}
          <div className="col-md-9 animate__animated animate__fadeInRight">
            <div className="card-body">
              <h4 className="card-title">Staking Promotion</h4>
              <h5 className="card-title">Earn 120% APY</h5>
              <p className="card-text">
                Take advantage of our limited-time staking offer to earn an incredible 120% Annual Percentage Yield. Secure your tokens and maximize your crypto earnings today!
              </p>
              <button className="btn btn-primary" onClick={openStakeToken}>
                Stake now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingPromoBanner;
