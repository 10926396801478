
const SETTINGS = {
    nodeContract:"0x53678bb0b23F4fAdc986C45b5Bf1001Bcd0b3cc1",
    //nodeContract:"0x6FeCb488C6B12c40bACD6a679A42a0b62eFe3b15",
    //nodeContract:"0x6FeCb488C6B12c40bACD6a679A42a0b62eFe3b15",
    collection: "0x3640d3056c4aB427178FcF65A5196721F07179C7",
    //collection: "0xFf7120a0889a301c0A12b92Bf62f2Fb81f9891Cc",
    nodeContractOld:"0xb7527bA057eF51D6f65D7392eAAD258533b351c2",
    //nodeContract:"0xF1588fa8D568c23FEf1eEfB8A9735DeE55455050",
    //nodeContract:"0x440Ba07f87Aa9E96141D6751B568874e85860eF3",
    //nodeContract:"0x9d7B02C95C92aE87Ad64f6F76d7a0322eb9f069e",
    //nodeContract:"0x90129Fc12c7f48B3fc1b338eEcdCfBF86902053B",
    mainContract2:"0x5753b2aEe7BA14F278b35eee8b05072014BCDC49",
    //mainContract2:"0xA079F6e2dac5890A252F1aF1f7B2540D34Af6Fe9",
    mainContract:"0xfFe8b92C7f8b6709ACA3CB84226344f8571d0870",
    //mainContract:"0x4ACD27FC89DC19B1BeBbBb6523640Ac82acad6Ea",
    //mainContract:"0xD9cD08D18Ba2bb82bC8d849c0E649CAdaad45027",
    //mainContract:"0x5A87Ebb147D16E51F9D1A16F7BEFc49193d1a757",
    //mainContract:"0x31fb2Dfa50f1979Cbb0b492F16EA22B4062D17A7",
    usdtAddress:"0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    //usdtAddress:"0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
    tokenAddress:"0x3c780F5cBF94De3EFCec964Af928D08c4508EeBE",
    //tokenAddress:"0x7ED0292393964Ee22188808BDefd31C2f691F7F6",
    stakingContractOld:"0x6D3114BB91730E3cE6d27F23DD655C1f7B51dC49",
    stakingContract:"0xB16768B518515B16c9Da875670F48684aD710197",
    stakingContractPromotion:"0x2EbB553E83FB580E06D2AeFa3D6354D327Ac92E1",
    //stakingContract:"0x62766990101d74f393F25e538191c00C40cB1fe4",
    //stakingContract:"0xc59f5A9412fE3dbd0ca3b4C76DD3eE5D8eFC6CCF",
    nativeSymbol: "AVAX",
    nativeIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
    tokenSymbol: "SPACEM",
    safeAddress: "0x511C645389eBe73aecFfaA57924d14ec46c13de8",
    modelUrl: "https://node2.spacemonkeez.io/",
    nodeName: "SPACEM NODE",
    owner: "0x6552679f5D35Cf214F2eEf52379D8A789d42Dbea",
    teamVestingContract: "0xdC05D5b810913A9BF9A33E6770F7241E6032D3f8",
    teamAddr: [
        "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "0xaF6BE7f42E071EE37B7D26C58679Abd61AcB5b44",
        "0x22E7742c913aFC58B26D631e2194Ba44d628E8f7",
        "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
        "0x9efBc950187440edEc52e7282F42543b31760A3C",
        "0x905E540F92C12D338E1677F50A5a1960eAe76445",
    ],
    AFFILIATE_DATA: {
        "affiliateStructure": [
          { "inviter": "225", "invitee": "232" },
          { "inviter": "225", "invitee": "255" },
          { "inviter": "225", "invitee": "448" },
          { "inviter": "225", "invitee": "416" },
          { "inviter": "225", "invitee": "586" },
          { "inviter": "225", "invitee": "611" },
          { "inviter": "225", "invitee": "708" },
          { "inviter": "416", "invitee": "2981" },
          { "inviter": "416", "invitee": "472" },
          { "inviter": "416", "invitee": "635" },
          { "inviter": "416", "invitee": "2567" },
          { "inviter": "416", "invitee": "2996" },
          { "inviter": "472", "invitee": "1028" },
          { "inviter": "472", "invitee": "1689" },
          { "inviter": "1028", "invitee": "1499" },
          { "inviter": "1028", "invitee": "1169" },
          { "inviter": "1499", "invitee": "1669" },
          { "inviter": "1669", "invitee": "1998" },
          { "inviter": "1669", "invitee": "1691" },
          { "inviter": "1998", "invitee": "3242" },
          { "inviter": "1998", "invitee": "2565" },
          { "inviter": "1998", "invitee": "2368" },
          { "inviter": "2368", "invitee": "2369" },
          { "inviter": "2369", "invitee": "2371" },
          { "inviter": "2369", "invitee": "2396" },
          { "inviter": "1691", "invitee": "1889" },
          { "inviter": "1889", "invitee": "2166" },
          { "inviter": "1889", "invitee": "1971" },
          { "inviter": "2166", "invitee": "2232" },
          { "inviter": "2232", "invitee": "2443" },
          { "inviter": "2232", "invitee": "2239" },
          { "inviter": "2443", "invitee": "2477" },
          { "inviter": "2239", "invitee": "2350" },
          { "inviter": "2239", "invitee": "2351" },
          { "inviter": "2350", "invitee": "2434" },
          { "inviter": "2434", "invitee": "2445" },
          { "inviter": "2445", "invitee": "2986" },
          { "inviter": "1971", "invitee": "1990" },
          { "inviter": "1990", "invitee": "2342" },
          { "inviter": "1990", "invitee": "2564" },
          { "inviter": "2564", "invitee": "2635" },
          { "inviter": "2564", "invitee": "2962" },
          { "inviter": "1169", "invitee": "1632" },
          { "inviter": "1632", "invitee": "1687" },
          { "inviter": "1632", "invitee": "1699" },
          { "inviter": "1687", "invitee": "1811" },
          { "inviter": "1811", "invitee": "1820" },
          { "inviter": "1820", "invitee": "2418" },
          { "inviter": "1820", "invitee": "2412" },
          { "inviter": "2412", "invitee": "2951" },
          { "inviter": "2951", "invitee": "2971" },
          { "inviter": "2971", "invitee": "2987" },
          { "inviter": "2987", "invitee": "3125" },
          { "inviter": "1699", "invitee": "1701" },
          { "inviter": "1701", "invitee": "1982" },
          { "inviter": "635", "invitee": "1066" },
          { "inviter": "1066", "invitee": "1122" },
          { "inviter": "1066", "invitee": "1788" },
          { "inviter": "1122", "invitee": "2167" },
          { "inviter": "1122", "invitee": "1789" },
          { "inviter": "1788", "invitee": "1810" },
          { "inviter": "1788", "invitee": "1790" },
          { "inviter": "1810", "invitee": "1834" },
          { "inviter": "1834", "invitee": "1945" },
          { "inviter": "1945", "invitee": "2001" },
          { "inviter": "1790", "invitee": "1836" },
          { "inviter": "1836", "invitee": "1856" },
          { "inviter": "2567", "invitee": "2614" },
          { "inviter": "708", "invitee": "1132" },
          { "inviter": "1132", "invitee": "1690" },
          { "inviter": "1132", "invitee": "1133" },
          { "inviter": "1690", "invitee": "3000" },
          { "inviter": "1690", "invitee": "1710" },
          { "inviter": "1710", "invitee": "1727" },
          { "inviter": "1727", "invitee": "2285" },
          { "inviter": "1727", "invitee": "1962" },
          { "inviter": "1727", "invitee": "1969" },
          { "inviter": "1727", "invitee": "1976" },
          { "inviter": "2285", "invitee": "2993" },
          { "inviter": "1133", "invitee": "1137" },
          { "inviter": "1133", "invitee": "1980" },
          { "inviter": "1980", "invitee": "1986" },
          { "inviter": "1986", "invitee": "2995" },
          { "inviter": "2995", "invitee": "3090" },
          { "inviter": "416", "invitee": "2982" },
          { "inviter": "416", "invitee": "2983" },
          { "inviter": "416", "invitee": "2984" },
          { "inviter": "416", "invitee": "2997" },
          { "inviter": "2239", "invitee": "2746" },
          { "inviter": "2239", "invitee": "2747" },
          { "inviter": "2239", "invitee": "2748" },
          { "inviter": "2239", "invitee": "2749" },
          { "inviter": "2239", "invitee": "2750" },
          { "inviter": "2239", "invitee": "2751" },
          { "inviter": "2239", "invitee": "2752" },
          { "inviter": "2239", "invitee": "2753" },
          { "inviter": "2239", "invitee": "2754" },
          { "inviter": "2239", "invitee": "2755" },
          { "inviter": "2239", "invitee": "2756" },
          { "inviter": "2239", "invitee": "2757" },
          { "inviter": "2239", "invitee": "2758" },
          { "inviter": "2239", "invitee": "2759" },
          { "inviter": "2239", "invitee": "2760" },
          { "inviter": "2239", "invitee": "2761" },
          { "inviter": "2239", "invitee": "2762" },
          { "inviter": "2239", "invitee": "2763" },
          { "inviter": "2239", "invitee": "2764" },
          { "inviter": "2239", "invitee": "2765" },
          { "inviter": "2239", "invitee": "2766" },
          { "inviter": "2239", "invitee": "2767" },
          { "inviter": "2239", "invitee": "2768" },
          { "inviter": "2239", "invitee": "2769" },
          { "inviter": "2239", "invitee": "2770" },
          { "inviter": "2239", "invitee": "2771" },
          { "inviter": "2239", "invitee": "2772" },
          { "inviter": "2239", "invitee": "2773" },
          { "inviter": "2239", "invitee": "2774" },
          { "inviter": "2239", "invitee": "2775" },
          { "inviter": "2239", "invitee": "2776" },
          { "inviter": "2239", "invitee": "2777" },
          { "inviter": "1727", "invitee": "1963" },
          { "inviter": "1727", "invitee": "1964" },
          { "inviter": "1727", "invitee": "1965" },
          { "inviter": "1727", "invitee": "1966" },
          { "inviter": "1727", "invitee": "1967" },
          { "inviter": "1727", "invitee": "1968" }
        ]
      }
};

const SETTINGS1 = {
    nodeContract:"0x8C973DCFa1f9f0327297F107033cC9877E97a7f7",
    mainContract:"0x84B67018b52BCE3ea2DF51b129273211dCBfCB4C",
    usdtAddress:"0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
    tokenAddress:"0xf61544C07f5787EA1Ad9A01A116F3e3DEc88b5A4",
    stakingContract:"",
    nativeSymbol: "AVAX",
    nativeIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
    tokenSymbol: "FNGR",
    safeAddress: "0xc8840755b1d6f1b931F16D7297c08Fe6Bf1d743f",
    collection: "0x720B3D26507566e39F33755cB0295FC2Ec71bDD3",
    modelUrl: "https://node2.spacemonkeez.io/",
    nodeName: "FINGER NODE",
};

const SETTINGS12 = {
    nodeContract:"0x247bA4a69fE58A2a9566074026e88E180b9b9Bf8",
    mainContract:"0x43563BA0aE892023F529Ee1E6692e6ED18E7814B",
    usdtAddress:"0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    tokenAddress:"0x14d1996D29616C1fb589522ce6A81a583f1c32bE",
    stakingContract:"0x41618517cd4474938EE0b7ddA183037735f4471E",

    nativeSymbol: "MATIC",
    nativeIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    tokenSymbol: "QVRS",

};
export default SETTINGS;

/*
ADDRESS BOOK - SPACEMONKEEZ 2024 - SAFE WALLETS AVAX network						
						
$SPACEM TOKEN		0xCBB9817023F1AA1c81BDDD5F6b08BAc859356e34				
						
SPACEM NODE COLLECTION 55% 27.500.000.000		0xFf7120a0889a301c0A12b92Bf62f2Fb81f9891Cc				
SPACEM NODE CONTRACT 	0% 0 SPACEM	0xFf7120a0889a301c0A12b92Bf62f2Fb81f9891Cc				


LIQUIDITY 3%	1.500.000.000 SPACEM	0xCEa4F1276CD3B765F6a3E683f2cDd33714A34a56				
						
OWNERS 50 %		0000 SPACEM0x978fB6030d54746de5a2dE77Faeb291e4B127565				
						
STAKING 10,8%	5.400.000.000 SPACEM	0xDEbD9A0aaceeb0afA419edD78FaE5b1CA4b567d5				
						
TREASURY 12%	6.000.000.000 SPACEM	0x905E540F92C12D338E1677F50A5a1960eAe76445				
						
TEAM 6%	3.000.000.000 SPACEM	0x95adE4FaB6b3f15181b6D1DFaF5A6431f86C08Bd				
						
ADVISORS 6%		0x9efBc950187440edEc52e7282F42543b31760A3C				
I have 27500000000 tokens for rewards. Once a day for 1826 days I want to distribute 15060240 tokens.
60 % of those daily tokens (9036144) goes for rewards to all exiting nodes evenly. and 40 % goes to staking pool.
How can I do that in this contract?

Each nft unlocks 451 tokens per day for 1826 days getting 825000 all together per nft. User has to claim reward when he wants.

27500000000 - 55% - NODE REWARDS
16500000000 NODES vse skupaj
825000 en node vse skupaj
451 na dan

Days: 1826
Rew per day: 15060240 
60% 9036144 NODES
10% 1506024 BURN
10% 1506024 STAKING
10% 1506024 Marketing
10% 1506024 NAGRADE



55 % VSEH toknov 27.500.000.000 $SPACEM toknov se bo razdelilo med lastnike NODE v roku 5 let / 60 mesecev oz v 1826 dneh. 
27.500.000.000 / 1826 dni (356 dni x 4 + 1 prestopno 366 ) = 15.060.240,96 toknov 

60 % teh toknov prejmejo lastniki NODEov, to je: 9.036.144,57 $SPACEM toknov na dan, vsak dan!!!
To je potrebno tako sprogramirati: vsak dan gre to med lastnike NODE zgornji znesek.
40 % je razlika, ki ostane med 15.060.240,96 – 9.036.144,57= 6.024.096,38 $SPACEM, ter se razdeli na 4 enake dele (4 x po 10 %)
10 % - 1.506.024,09 $SPACEM - BURN
10 % - 1.506.024,09 $SPACEM - STAKING bazen
10 % - 1.506.024,09 $SPACEM - Marketing, za vzdrževanje projekta – poseben bazen 
10 % - 1.506.024,09 $SPACEM – za NAGRADE, ki se delijo tedensko med lastnike NODE-kot žreb, delamo ročno in objavimo vse nagrajence - 

UROŠ, upam, da bo šlo tole pripraviti na način, ki smo si ga zamislili.
OCENA in PREDVIDEVANJA PROJEKTA SPACE MONKEEZ:

Vsekakor verjamem, da bo projekt noro uspel in da bomo vsi prispevali max, kar vsak od nas zmore in zna. 

Zelo enostavno je predvideti pri začetnem MCAP $196.000, cena kovanca 0,00006 USDT da bo zelo enostavno projekt naredil 10x v zelo kratkem, to pomeni 
2M$ Mcap, in brez večjih težav nato
20 M$ v nekaj mesecih…če prav postavimo vse skupaj, pa imamo pred seboj 1000x projekt – 
200 M$ MCap in če MEME kovanci (kot PEPE, npr) pride do 4 MRD MCap, imamo velike šanse, da udarimo na 1 MRD MCap, kar bi pomenilo – 5.000 x projekt.

Pri vložku 1.000 USDT na dan lansiranja po ceni 0,00006 USDT, bo v primeru 1 MRD MCap ta tisočak vreden natančno 5 mio USDT. Je to možno? Absolutno. Vesel, res vesel bom, ko naredimo 10 x in prodamo prvih 2.000 kos NODE-ov, potem bo vse le še letelo…in meja ni…

Gremooo!

Še v denarju, ki ga bodo prejemali lastniki NODE-ov:

Po ceni 0,00006 (začetna cena ob lansiranju tokna) razdelimo med lastnike NODEov znesek v višini 16.800,00 USDT / mesec, če cena tokna ostane enaka. Ko MCap pride na 2 MIO, bodo razdeljeni kovanci mesečno vredni 168.000,00 USDT, če pa gre MCap na 20 Mio, pa se vsak mesec razdeli 1.68 Mio USD med lastnike NODE-ov. 
*/